import { Range } from './date-picker.model';
import { AttachmentModel } from './attachment-table.model';
import { CourseAssignmentQuestion, QuizAction } from './question.model';
import { ColorPalette } from './color-palette.model';
import {
    CourseAssignmentSubmission,
    CourseAssignmentSubmissionStatus,
    SubmissionExtraAttempt,
} from './course-assignment-submission';
import { Course } from './course.model';
import { TranslatableString } from './translateble-string.model';
import { QuizPaginationType } from './quizPagination.enum';
import { GradeExcuse } from './grade-excuse.model';
import { NotificationsSettings } from './course.model';
import { GradeCategory } from './grade-categories-template.model';
import { GradeSettings } from './school.model';

export class CourseGradeDTO {
    constructor(
        public assignmentId: string,
        public submissionId: string,
        public grade: number
    ) {
        this.assignmentId = assignmentId ? assignmentId : null;
        this.submissionId = submissionId ? submissionId : null;
        this.grade = grade || grade == 0 ? grade : null;
    }
}

export class StudentAssignmentSearch {
    constructor(
        public userId?: string,
        public courseIds?: string[],
        public dateRange?: Range,
        public periodId?: string,
        public studentId?: string,
        public schoolId?: string,
        public statuses?: CourseAssignmentStatus[],
        public groupType?: StudentAssignmentGroupType,
        public showOnlyFavourites?: boolean,
        public academicYearId?: string
    ) {
        this.userId = this.userId ?? null;
        this.courseIds = this.courseIds ?? [];
        this.periodId = this.periodId ?? null;
        this.schoolId = this.schoolId ?? null;
        this.dateRange = this.dateRange ?? { startDate: null, endDate: null };
        this.statuses = this.statuses ?? [CourseAssignmentStatus.PUBLISHED];
        this.groupType = this.groupType ?? StudentAssignmentGroupType.DATE;
        this.showOnlyFavourites = this.showOnlyFavourites ?? false;
        this.academicYearId = academicYearId ?? null;
    }
}

export class DueDateAssignment {
    constructor(
        public dueDateTime?: Date,
        public type?: CourseAssignmentType,
        public courseId?: string,
        public marked?: boolean,
        public groupDefaultType?: StudentAssignmentGroupDefaultType,
        public assignments?: CourseAssignment[]
    ) {}
}
export class CourseAssignmentSearch {
    constructor(
        public courseIds?: string[],
        public courseId?: string,
        public periodId?: string,
        public schoolId?: string,
        public studentId?: string,
        public type?: CourseAssignmentType,
        public types?: CourseAssignmentType[],
        public status?: CourseAssignmentStatus,
        public statuses?: string[],
        public name?: string,
        public dueDateRange?: Range,
        public pageSize?: number,
        public pageIndex?: number,
        public academicYearId?: string,
        public gradeLevelId?: string
    ) {}
}

export enum CourseAssignmentType {
    HOMEWORK = 'HOMEWORK',
    PROJECT = 'PROJECT',
    QUIZ = 'QUIZ',
    OTHER = 'OTHER',
}

export enum CourseAssignmentStatus {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
    RESULTS_PUBLISHED = 'RESULTS_PUBLISHED',
}

export enum StudentAssignmentGroupType {
    DEFAULT = 'DEFAULT',
    COURSE = 'COURSE',
    TYPE = 'TYPE',
    DATE = 'DATE',
}

export enum StudentAssignmentGroupDefaultType {
    OVERDUE = 'OVERDUE',
    UPCOMING = 'UPCOMING',
    UNDATED = 'UNDATED',
    PAST = 'PAST',
}

export enum CourseAssignmentSubscribersType {
    ALL = 'ALL',
    GROUP = 'GROUP',
    STUDENT = 'STUDENT',
}

export enum CourseAssignmentSubmissionType {
    ONLINE = 'ONLINE',
    PRINT = 'PRINT',
}
export class IPageableCourseAssignment {
    id?: string;
    name?: string;
    courseName?: string;
    courseId?: string;
    type?: CourseAssignmentType;
    status?: CourseAssignmentStatus;
    periodId?: string;
    periodName?: string;
    translatePeriodName?: TranslatableString[];
    submissionFromDateTime?: Date;
    dueDateTime?: Date;
    quizCloseDateTime?: Date;
    quizOpenDateTime?: Date;
    subscribersType?: CourseAssignmentSubscribersType;
    assignedTo?: AssignmentSubscriber[]; // GROUP or Student
    lockedFromDateTime?: Date;
    lockedUntilDateTime?: Date;
    gradeSettings?: GradeSettings;
    grades?: CourseGrade[];
    nonDraftSubmissionsCount?: number;
    submissionsLocked?: boolean;
}
export class CourseAssignmentWithComments {
    id?: string;
    name?: string;
    unReadCommentsCount?: number;
    status?: CourseAssignmentStatus;
    createdDate?: Date;
    dueDate?: Date;
}
export class CourseAssignment {
    constructor(
        public id?: string,
        public name?: string,
        public courseName?: string,
        public css?: ColorPalette,
        public description?: string,
        public courseId?: string,
        public schoolId?: string,
        public periodId?: string,
        public periodName?: string,
        public translatePeriodName?: TranslatableString[],
        public course?: Course,
        public type?: CourseAssignmentType,
        public status?: CourseAssignmentStatus,
        public audioDescriptions?: AttachmentModel[],
        public attachments?: AttachmentModel[],
        public submissionFromDate?: Date,
        public submissionFromTime?: string,
        public submissionFromDateTime?: Date,
        public dueDate?: Date,
        public dueTime?: string,
        public dueDateTime?: Date,
        public cutOffDate?: Date,
        public cutOffTime?: string,
        public cutOffDateTime?: Date,
        public termGradeDueDate?: Date,
        public academicYearGradeDueDate?: Date,
        public gradingDueTime?: string,
        public gradingDueDateTime?: Date,
        public gradeCategory?: GradeCategory,
        public submissionType?: CourseAssignmentSubmissionType,
        public textSubmissionEnabled?: boolean,
        public fileSubmissionEnabled?: boolean,
        public maxSubmissionTextSize?: number,
        public maxSubmissionUploadFiles?: number,
        public maxSubmissionFileSize?: number,
        public acceptedSubmissionFileTypes?: string[],
        public maxAttempts?: number,
        public reviewAsCommentEnabled?: boolean,
        public reviewAsFileEnabled?: boolean,
        public hideGrades?: boolean,
        public notificationSettings?: NotificationsSettings,
        public subscribersType?: CourseAssignmentSubscribersType,
        public assignedTo?: AssignmentSubscriber[],
        public submissionInfo?: SubmissionInfo,
        public lastSubmissionStatus?: CourseAssignmentSubmissionStatus,
        public nonDraftSubmissionsCount?: number,
        public unReadCommentCount?: number,
        public gradeSettings?: CourseAssignmentGradeSettings,
        public submissions?: CourseAssignmentSubmission[],
        public extraAttempts?: SubmissionExtraAttempt[],
        public grades?: CourseGrade[],
        public discussionEnabled?: boolean,
        public lastSubmissionTime?: Date,
        public lockedFromDate?: Date,
        public lockedFromTime?: string,
        public lockedFromDateTime?: Date,
        public lockedUntilDate?: Date,
        public lockedUntilTime?: string,
        public lockedUntilDateTime?: Date,
        public quizOpenDate?: Date,
        public quizOpenTime?: string,
        public quizOpenDateTime?: Date,
        public quizCloseDate?: Date,
        public quizCloseTime?: string,
        public quizCloseDateTime?: Date,
        public quizDuration?: number,
        public quizPaginationType?: QuizPaginationType,
        public quizQuestionPerPage?: number,
        public shuffleQuestions?: boolean,
        public tillNextLesson?: boolean,
        public questions?: CourseAssignmentQuestion[],
        public grade?: CourseGrade,
        public studentPoint?: number,
        public maxPoint?: number,
        public quizSettings?: CourseAssignmentQuizSettings,
        public quizDetail?: QuizDetailInfo,
        public submissionsLocked?: boolean,
        public comments?: TeacherCommentInterface[],
        public availableUntilDateTime?: Date,
        public academicPeriodId?: string
    ) {
        this.id = id ? id : null;
        this.name = name ? name : '';
        this.courseName = courseName ? courseName : '';
        this.css = css ? css : { name: 'Default', value: '#fff', foreground: '#000' };
        this.description = description ? description : '';
        this.courseId = courseId ? courseId : null;
        this.schoolId = schoolId ? schoolId : null;
        this.periodId = periodId ? periodId : null;
        this.periodName = periodName ? periodName : null;
        this.translatePeriodName = translatePeriodName ? translatePeriodName : [];

        this.course = course ? course : null;
        this.type = type ? type : null;
        this.status = status ? status : CourseAssignmentStatus.DRAFT;
        this.audioDescriptions = audioDescriptions ? audioDescriptions : [];
        this.attachments = attachments ? attachments : [];
        this.submissionFromDate = submissionFromDate ? submissionFromDate : null;
        this.submissionFromTime = submissionFromTime ? submissionFromTime : null;
        this.submissionFromDateTime = submissionFromDateTime ? submissionFromDateTime : null;
        this.dueDate = dueDate ? dueDate : null;
        this.dueTime = dueTime ? dueTime : null;
        this.dueDateTime = dueDateTime ? dueDateTime : null;
        this.cutOffDate = cutOffDate ? cutOffDate : null;
        this.cutOffTime = cutOffTime ? cutOffTime : null;
        this.cutOffDateTime = cutOffDateTime ? cutOffDateTime : null;
        this.termGradeDueDate = termGradeDueDate ? termGradeDueDate : null;
        this.academicYearGradeDueDate = academicYearGradeDueDate ? academicYearGradeDueDate : null;
        this.gradingDueTime = gradingDueTime ? gradingDueTime : null;
        this.gradingDueDateTime = gradingDueDateTime ? gradingDueDateTime : null;
        (this.gradeCategory = gradeCategory ? gradeCategory : new GradeCategory()),
            (this.submissionType = submissionType ? submissionType : CourseAssignmentSubmissionType.ONLINE);
        this.textSubmissionEnabled = textSubmissionEnabled ? textSubmissionEnabled : true;
        this.fileSubmissionEnabled = fileSubmissionEnabled ? fileSubmissionEnabled : true;
        this.maxSubmissionTextSize = maxSubmissionTextSize ? maxSubmissionTextSize : 0;
        this.maxSubmissionUploadFiles = maxSubmissionUploadFiles ? maxSubmissionUploadFiles : 0;
        this.maxSubmissionFileSize = maxSubmissionFileSize ? maxSubmissionFileSize : 0;
        this.acceptedSubmissionFileTypes = acceptedSubmissionFileTypes ? acceptedSubmissionFileTypes : [];
        this.maxAttempts = maxAttempts ? maxAttempts : 0;
        this.reviewAsCommentEnabled = reviewAsCommentEnabled ? reviewAsCommentEnabled : true;
        this.reviewAsFileEnabled = reviewAsFileEnabled ? reviewAsFileEnabled : true;
        this.hideGrades = hideGrades ? hideGrades : false;
        this.notificationSettings = notificationSettings ? notificationSettings : new NotificationsSettings();
        this.subscribersType = subscribersType ? subscribersType : CourseAssignmentSubscribersType.ALL;
        this.assignedTo = assignedTo ? assignedTo : [];
        this.submissionInfo = submissionInfo ? submissionInfo : new SubmissionInfo();
        this.lastSubmissionStatus = lastSubmissionStatus ? lastSubmissionStatus : null;
        this.nonDraftSubmissionsCount = nonDraftSubmissionsCount ? nonDraftSubmissionsCount : 0;
        this.unReadCommentCount = unReadCommentCount ? unReadCommentCount : 0;
        this.gradeSettings = gradeSettings ? gradeSettings : new CourseAssignmentGradeSettings();
        this.submissions = submissions ? submissions : [];
        this.extraAttempts = extraAttempts ? extraAttempts : [];
        this.grades = grades ? grades : [];
        this.discussionEnabled = discussionEnabled ? discussionEnabled : true;
        this.lastSubmissionTime = lastSubmissionTime ? lastSubmissionTime : null;
        this.lockedFromDate = lockedFromDate ? lockedFromDate : null;
        this.lockedFromTime = lockedFromTime ? lockedFromTime : null;
        this.lockedFromDateTime = lockedFromDateTime ? lockedFromDateTime : null;
        this.lockedUntilDate = lockedUntilDate ? lockedUntilDate : null;
        this.lockedUntilTime = lockedUntilTime ? lockedUntilTime : null;
        this.lockedUntilDateTime = lockedUntilDateTime ? lockedUntilDateTime : null;
        this.quizOpenDate = quizOpenDate ? quizOpenDate : null;
        this.quizOpenTime = quizOpenTime ? quizOpenTime : null;
        this.quizOpenDateTime = quizOpenDateTime ? quizOpenDateTime : null;
        this.quizCloseDate = quizCloseDate ? quizCloseDate : null;
        this.quizCloseTime = quizCloseTime ? quizCloseTime : null;
        this.quizCloseDateTime = quizCloseDateTime ? quizCloseDateTime : null;
        this.quizDuration = quizDuration ? quizDuration : 0;
        this.quizPaginationType = quizPaginationType ? quizPaginationType : QuizPaginationType.ONE_BY_ONE;
        this.quizQuestionPerPage = quizQuestionPerPage ? quizQuestionPerPage : 2;
        this.shuffleQuestions = shuffleQuestions ? shuffleQuestions : false;
        this.tillNextLesson = tillNextLesson ? tillNextLesson : false;
        this.questions = questions ? questions : [];
        this.grade = grade ? grade : null;
        this.studentPoint = studentPoint ?? null;
        this.maxPoint = maxPoint ?? null;
        this.quizSettings = quizSettings ? quizSettings : new CourseAssignmentQuizSettings();
        this.quizDetail = quizDetail ? quizDetail : null;
        this.submissionsLocked = submissionsLocked ? submissionsLocked : false;
        this.comments = comments ? comments : null;
        this.availableUntilDateTime = availableUntilDateTime ? availableUntilDateTime : null;
        this.academicPeriodId = academicPeriodId ?? academicPeriodId;
    }
}

export class StudentAssignmentInfo {
    constructor(
        public id?: string,
        public name?: string,
        public courseName?: string,
        public teacherFirstName?: string,
        public teacherMiddleName?: string,
        public teacherLastName?: string,
        public periodName?: string,
        public translatePeriodName?: TranslatableString[],
        public description?: string,
        public type?: CourseAssignmentType,
        public status?: CourseAssignmentStatus,
        public audioDescriptions?: AttachmentModel[],
        public attachments?: AttachmentModel[],
        public submissionFromDateTime?: Date,
        public dueDateTime?: Date,
        public cutOffDateTime?: Date,
        public submissionType?: CourseAssignmentSubmissionType,
        public textSubmissionEnabled?: boolean,
        public fileSubmissionEnabled?: boolean,
        public maxSubmissionTextSize?: number,
        public maxSubmissionFileSize?: number,
        public maxAttempts?: number,
        public subscribersType?: CourseAssignmentSubscribersType,
        public assignedTo?: AssignmentSubscriber[],
        public groupId?: string,
        public enableGrading?: boolean,
        public submissions?: CourseAssignmentSubmission[],
        public extraAttempts?: SubmissionExtraAttempt[],
        public discussionEnabled?: boolean,
        public grade?: CourseGrade,
        public studentId?: string,
        public quizOpenDateTime?: Date,
        public quizCloseDateTime?: Date,
        public quizDuration?: number,
        public quizAction?: QuizAction,
        public quizExtraAttempt?: SubmissionExtraAttempt,
        public gradeSettings?: CourseAssignmentGradeSettings,
        public lockedFromDateTime?: Date,
        public lockedUntilDateTime?: Date,
        public totalQuestions?: number,
        public quizPoint?: number,
        public quizWeight?: number,
        public submissionsLocked?: boolean,
        public quizDetails?: QuizDetailInfo[]
    ) {
        this.lockedFromDateTime = lockedFromDateTime ? lockedFromDateTime : null;
        this.lockedUntilDateTime = lockedUntilDateTime ? lockedUntilDateTime : null;
        this.name = name ? name : '';
        this.courseName = courseName ? courseName : '';
        this.periodName = periodName ? periodName : null;
        this.translatePeriodName = translatePeriodName ? translatePeriodName : [];
        this.description = description ? description : '';
        this.type = type ? type : null;
        this.status = status ? status : CourseAssignmentStatus.DRAFT;
        this.audioDescriptions = audioDescriptions ? audioDescriptions : [];
        this.attachments = attachments ? attachments : [];
        this.submissionFromDateTime = submissionFromDateTime ? submissionFromDateTime : null;
        this.dueDateTime = dueDateTime ? dueDateTime : null;
        this.cutOffDateTime = cutOffDateTime ? cutOffDateTime : null;
        this.submissionType = submissionType ? submissionType : CourseAssignmentSubmissionType.ONLINE;
        this.textSubmissionEnabled = textSubmissionEnabled ? textSubmissionEnabled : true;
        this.fileSubmissionEnabled = fileSubmissionEnabled ? fileSubmissionEnabled : true;
        this.maxSubmissionTextSize = maxSubmissionTextSize ? maxSubmissionTextSize : 0;
        this.maxSubmissionFileSize = maxSubmissionFileSize ? maxSubmissionFileSize : 0;
        this.maxAttempts = maxAttempts ? maxAttempts : 0;
        this.subscribersType = subscribersType ? subscribersType : CourseAssignmentSubscribersType.ALL;
        this.assignedTo = assignedTo ? assignedTo : [];
        this.groupId = groupId ? groupId : null;
        this.enableGrading = enableGrading ? enableGrading : false;
        this.submissions = submissions ? submissions : [];
        this.extraAttempts = extraAttempts ? extraAttempts : [];
        this.discussionEnabled = discussionEnabled ? discussionEnabled : true;
        this.grade = grade ? grade : null;
        this.studentId = this.studentId ? this.studentId : '';
        this.quizOpenDateTime = quizOpenDateTime ? quizOpenDateTime : null;
        this.quizCloseDateTime = quizCloseDateTime ? quizCloseDateTime : null;
        this.quizDuration = quizDuration ? quizDuration : 0;
        this.quizAction = quizAction ? quizAction : null;
        this.quizExtraAttempt = quizExtraAttempt ? quizExtraAttempt : null;
        this.gradeSettings = gradeSettings ? gradeSettings : null;
        this.totalQuestions = totalQuestions ? totalQuestions : null;
        this.quizPoint = quizPoint ? quizPoint : null;
        this.quizWeight = quizWeight ? quizWeight : null;
        this.submissionsLocked = submissionsLocked ? submissionsLocked : false;
        this.quizDetails = quizDetails ? quizDetails : [];
    }
}

export class AssignmentSubscriber {
    constructor(
        public id?: string,
        public firstName?: string,
        public middleName?: string,
        public lastName?: string,
        public userId?: string,
        public members?: AssignmentSubscriber[],
        public imageUrl?: string,
        public enrolledClass?: string,
        public studentId?: string
    ) {
        this.id = id ? id : null;
        this.firstName = firstName ? firstName : null;
        this.middleName = middleName ? middleName : null;
        this.lastName = lastName ? lastName : null;
        this.userId = userId ? userId : null;
        this.members = members ? members : [];
        this.imageUrl = imageUrl ? imageUrl : null;
        this.enrolledClass = enrolledClass ? enrolledClass : null;
        this.studentId = studentId ? studentId : null;
    }
}

export class CourseAssignmentQuizSettings {
    constructor(
        public randomQuestions?: boolean,
        public differentQuestionsToEveryOne?: boolean,
        public questionsByDifficulty?: QuestionsByDifficulty[],
        public showQuizAnswers?: boolean,
        public showCorrectAnswers?: boolean
    ) {
        this.randomQuestions = randomQuestions ? randomQuestions : false;
        this.differentQuestionsToEveryOne = differentQuestionsToEveryOne ? differentQuestionsToEveryOne : false;
        this.questionsByDifficulty = questionsByDifficulty ? questionsByDifficulty : [];
        this.showQuizAnswers = this.showQuizAnswers ? this.showQuizAnswers : false;
        this.showCorrectAnswers = this.showCorrectAnswers ? this.showCorrectAnswers : false;
    }
}

export class QuestionsByDifficulty {
    constructor(
        public index?: number,
        public gradeLevelId?: string,
        public curriculumId?: string,
        public curriculumName?: string,
        public difficultyLevel?: number,
        public questionSize?: number
    ) {
        this.index = index ? index : 0;
        this.gradeLevelId = gradeLevelId ? gradeLevelId : null;
        this.curriculumId = curriculumId ? curriculumId : null;
        this.curriculumName = curriculumName ? curriculumName : null;
        this.difficultyLevel = difficultyLevel ? difficultyLevel : 1;
        this.questionSize = questionSize ? questionSize : 1;
    }
}

export class SubmissionInfo {
    constructor(
        public assignedCount?: number,
        public submittedCount?: number,
        public reviewedCount?: number
    ) {
        this.assignedCount = assignedCount ? assignedCount : 0;
        this.submittedCount = submittedCount ? submittedCount : 0;
        this.reviewedCount = reviewedCount ? reviewedCount : 0;
    }
}

export class CourseAssignmentGradeSettings {
    constructor(
        public enableGrading?: boolean,
        public gradeCategoryId?: string,
        public weight?: number,
        public maxPoint?: number
    ) {
        this.enableGrading = enableGrading ? enableGrading : false;
        this.gradeCategoryId = gradeCategoryId ? gradeCategoryId : null;
        this.weight = weight ? weight : 1;
        this.maxPoint = maxPoint ?? null;
    }
}

export class CourseGrade {
    constructor(
        public studentId?: string,
        public grade?: number,
        public excuse?: GradeExcuse
    ) {
        this.studentId = studentId ? studentId : null;
        this.grade = grade ? grade : null;
        this.excuse = excuse ? excuse : null;
    }
}

export class GradebookAssignment {
    constructor(
        public id?: string,
        public name?: string,
        public gradeCategoryId?: string,
        public weight?: number,
        public periodId?: string,
        public courseId?: string,
        public maxPoint?: number,
        public date?: Date
    ) {
        this.id = id ? id : null;
        this.name = name ? name : null;
        this.gradeCategoryId = gradeCategoryId ? gradeCategoryId : null;
        this.weight = weight ? weight : 1;
        this.periodId = periodId ? periodId : null;
        this.courseId = courseId ? courseId : null;
        this.maxPoint = maxPoint ? maxPoint : null;
        this.date = date ?? null;
    }
}
export class QuizDetailInfo {
    constructor(
        public id?: string,
        public running?: boolean,
        public startTime?: Date,
        public endTime?: Date,
        public status?: CourseAssignmentSubmissionStatus,
        public duration?: number,
        public point?: number,
        public weight?: number,
        public maxPoint?: number,
        public totalQuestions?: number
    ) {
        this.id = id ? id : null;
        this.running = running ? running : false;
        this.startTime = startTime ? startTime : null;
        this.endTime = endTime ? endTime : null;
        this.status = status ? status : null;
        this.duration = duration ? duration : null;
        this.point = point ? point : null;
        this.weight = weight ? weight : null;
        this.maxPoint = maxPoint ? maxPoint : null;
        this.totalQuestions = totalQuestions ? totalQuestions : null;
    }
}

export interface TeacherCommentInterface {
    id?: string;
    comment?: string;
    userId?: string;
    userName?: string;
    parentId?: null | string;
    createdAt?: Date;
    show?: boolean;
    deleted?: boolean;
}
